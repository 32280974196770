import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";

const BlogDetailPage = () => {
    const { blogId } = useParams();
    const appContext = React.useContext(AppContext);
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];
    const navigate = useNavigate();

    // Find the blog by ID
    const blog = appContext.blogsData.allBlogs.find(b => b.id === parseInt(blogId));

    // State management
    const [excerptPart1, setExcerptPart1] = useState('');
    const [excerptPart2, setExcerptPart2] = useState('');
    const imgRef = useRef(null);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768); // Adjust threshold for small screens

    // Handle case if blog is not found
    useEffect(() => {
        if (!blog) {
            navigate('/error'); // Change '/error' to your error page route
        } else {
            splitExcerpt(blog.excerpt);
        }

        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768); // Update state based on screen width
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [blog, navigate]);

    // Function to split the excerpt
    const splitExcerpt = (excerpt) => {
        const maxChars = 2000; // Maximum characters for the first part

        // If the excerpt length is less than maxChars, just return it all
        if (excerpt.length <= maxChars) {
            setExcerptPart1(excerpt);
            setExcerptPart2('');
            return;
        }

        // Get the first 1000 characters
        const part1 = excerpt.slice(0, maxChars);

        // Find the last space in the first part to split at the last complete word
        const lastSpaceIndex = part1.lastIndexOf(' ');

        // Create the first part and second part
        const finalPart1 = part1.slice(0, lastSpaceIndex);
        const finalPart2 = excerpt.slice(lastSpaceIndex + 1); // Remaining text

        setExcerptPart1(finalPart1);
        setExcerptPart2(finalPart2);
    };

    return (
        <section className={`${theme.textNormal} ${theme.background} body-font`}>
            <div className="container mx-auto py-24 px-5">
                <h1 className={`sm:text-4xl text-3xl font-medium title-font mb-4 ${theme.textFocus}`}>{blog ? blog.title : 'Loading...'}</h1>
                <div className="flex flex-col lg:flex-row">
                    {/* Image with rounded corners */}
                    <img 
                        ref={imgRef}
                        className="mb-6 lg:w-1/2 w-full object-cover object-center lg:mr-6 rounded-lg"
                        src={blog ? blog.image : ''} 
                        alt={blog ? blog.title : 'Loading...'} 
                    />
                    <div className="flex flex-col lg:w-1/2">
                        {/* Content for large screens */}
                        <div className="flex-grow mb-4">
                            {isSmallScreen ? (
                                <p className="leading-relaxed text-lg mb-4">{blog ? blog.excerpt : 'Loading...'}</p> // Show full excerpt on small screens
                            ) : (
                                <p className="leading-relaxed text-lg mb-4">{excerptPart1}</p> // Show split excerpt on larger screens
                            )}
                        </div>
                    </div>
                </div>
                {/* Remaining content below the image */}
                {!isSmallScreen && (
                    <div>
                        <p className="leading-relaxed text-lg mb-4">{excerptPart2}</p>
                    </div>
                )}
                <Link className={`text-brand-400 hover:text-brand-500 inline-flex items-center mt-4`} to="/blogs">
                    Back to Blogs
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                </Link>
            </div>
        </section>
    );
};

export default BlogDetailPage;
