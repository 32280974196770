import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";

export default function BlogsPage() {
    const appContext = useContext(AppContext);
    const themeContext = useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];
    const blogsData = appContext.blogsData.allBlogs;
    
    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 5;
    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogsData.slice(indexOfFirstBlog, indexOfLastBlog);

    const totalPages = Math.ceil(blogsData.length / blogsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top
    };

    useEffect(() => {
        // Scroll to the top of the page whenever `currentPage` changes
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentPage]);

    return (
        <section className={`${theme.textNormal} ${theme.background} body-font`}>
            <div className="container mx-auto px-5 py-24">
                <h1 className={`text-3xl font-medium title-font mb-12 text-center ${theme.textFocus}`}>Our Blogs</h1>
                <div className="flex flex-col space-y-10">
                    {currentBlogs.map((blog, index) => (
                        <div
                            key={blog.id}
                            className={`flex flex-col lg:flex-row ${index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'} items-center lg:items-start space-y-6 lg:space-y-0`}
                        >
                            {/* Blog Image */}
                            <div className="lg:w-1/2 w-full">
                                <img
                                    src={blog.image}
                                    alt={blog.title}
                                    className="object-cover object-center rounded-lg shadow-lg w-full h-64 lg:h-80"
                                />
                            </div>

                            {/* Blog Content */}
                            <div className="lg:w-1/2 w-full lg:px-10">
                                <h2 className={`text-2xl font-semibold ${theme.textFocus}`}>{blog.title}</h2>
                                <p className="mt-4 text-base leading-relaxed">
                                    {blog.excerpt.length > 150 ? `${blog.excerpt.slice(0, 150)}...` : blog.excerpt}
                                </p>
                                <a
                                    href={`/blogs/${blog.id}`}
                                    className="text-brand-400 hover:text-brand-500 inline-flex items-center mt-4"
                                >
                                    Read More
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        className="w-4 h-4 ml-2"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-12">
                    {Array.from({ length: totalPages }, (_, i) => (
                        <button
                            key={i}
                            onClick={() => handlePageChange(i + 1)}
                            className={`px-4 py-2 mx-1 rounded-md ${currentPage === i + 1 ? 'bg-brand-500 text-white' : 'bg-gray-200'}`}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
            </div>
        </section>
    );
}
